import React, { createContext, useState, useContext } from 'react';

export const ActiveComponentContext = createContext();

export const useActiveComponent = () => useContext(ActiveComponentContext);

export const ActiveComponentProvider = ({ children }) => {
  const [activeComponent, setActiveComponent] = useState('Home');

  return (
    <ActiveComponentContext.Provider value={{ activeComponent, setActiveComponent }}>
      {children}
    </ActiveComponentContext.Provider>
  );
};