import React, { useState, createContext, useContext } from 'react';

// Create the context
export const ControlsContext = createContext();

// Create the provider component
export const ControlsProvider = ({ children }) => {
    const [isLightDimmed, setIsLightDimmed] = useState(false);
    const [isAudioMuted, setIsAudioMuted] = useState(false);

    return (
        <ControlsContext.Provider value={{ isLightDimmed, setIsLightDimmed, isAudioMuted, setIsAudioMuted }}>
            {children}
        </ControlsContext.Provider>
    );
};

// Create a custom hook for accessing the controls context
export const useControls = () => useContext(ControlsContext);