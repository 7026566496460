import React, { Suspense, useRef, useEffect, useState, useCallback } from 'react';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ActiveComponentProvider } from './contexts/ActiveComponentContext';
import { Howl } from 'howler';
import { useControls } from './contexts/ControlsProvider';
import ScrollContext from './contexts/ScrollContext';
import LinearProgress from '@mui/material/LinearProgress';

const Controls = React.lazy(() => import('./components/Controls'));
const Overlay = React.lazy(() => import('./pages/Overlay'));
const Background = React.lazy(() => import('./Background'));

function App({ musicOption, audioPlayer, audioStarted }) {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [displayApp, setDisplayApp] = useState(false);
  const [display, setDisplay] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const { isAudioMuted } = useControls();
  const [isLoaded, setIsLoaded] = useState(false);

  const appElement = useRef(null);
  const fixedBackground = useRef(null);

  const handleScroll = useCallback(() => {
    if (appElement.current) {
      const { scrollTop, scrollHeight, clientHeight } = appElement.current;
      const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;
      setIsScrolledToBottom(scrolledToBottom);
    }

    AOS.refresh();
  }, []);
  
  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 5000,
      once: true,
    });

    // Once everything is set up, set isLoaded to true
    setIsLoaded(true);

    const checkForAppElement = setInterval(() => {
      if (appElement.current) {
        appElement.current.addEventListener('scroll', handleScroll);
        clearInterval(checkForAppElement);
      }
    }, 100);
  
    window.addEventListener('resize', setFixedBackgroundHeight);
  
    if (musicOption === 'yes') {
      const timer = setTimeout(() => {
        setDisplayApp(true);
      }, 5700); // Controls display delay while music plays
    
      return () => clearTimeout(timer);
    } else {
      setDisplayApp(true);
      if (audioPlayer.current instanceof Howl) {
        audioPlayer.current.stop();
      }
    }
    
  
    return () => {
      if (appElement.current) {
        appElement.current.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', setFixedBackgroundHeight);
      if (audioPlayer.current instanceof Howl) {
        audioPlayer.current && audioPlayer.current.stop();
      }
    };
  }, [handleScroll, musicOption]);

  useEffect(() => {
    setFadeOut(true);
    const timer = setTimeout(() => {
      setDisplay(displayApp);
      setFadeOut(false);
    }, 500); // Delay of 0.5 second

    return () => clearTimeout(timer); // Clear the timer if the component is unmounted
  }, [displayApp]);

  function setFixedBackgroundHeight() {
    if (fixedBackground.current) {
      fixedBackground.current.style.height = `${window.innerHeight}px`;
    }
  }

  // Call the function initially
  setFixedBackgroundHeight();

  return (
    (musicOption === 'no' || audioStarted) && (
      <ActiveComponentProvider>
        <ScrollContext.Provider value={{ isScrolledToBottom, setIsScrolledToBottom }}>
          <div className="App" ref={appElement}>
            {(display && isLoaded) ? (
              <Suspense fallback={<div style={{ backgroundColor: 'black', width: '100%', height: '100%' }}></div>}>
                <Controls musicOption={musicOption} />
                <Overlay />
                <div className="fixedBackground" ref={fixedBackground}>
                  <Background />
                </div>
              </Suspense>
            ) : (
              <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', opacity: fadeOut ? 0 : 1, transition: 'opacity 1s' }}>
                <LinearProgress color="inherit" />
              </div>
            )}
          </div>
        </ScrollContext.Provider> 
      </ActiveComponentProvider>
    )
  );
}

export default App;
