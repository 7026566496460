import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Howl } from 'howler';
import { ControlsProvider } from './contexts/ControlsProvider';
import bgSong from './Audio/GLBTM.mp3';

const root = ReactDOM.createRoot(document.getElementById('root'));

const BootstrapButton = styled(Button)({
  width: '45%',
  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.1) 80%)', // linear gradient background
  border: 'none', // remove border
  fontWeight: '300', // lighter font weight
  transition: 'box-shadow 0.3s', 
  color: 'white',
  padding: '.5rem',
  fontSize: '2rem', // reduce font size
  borderRadius: '10px', // increase border radius
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: 'silver',
    borderColor: 'none',
    color: 'white',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
  },
  '&:not(:last-child)': {
    marginRight: '10%', // add margin to the right of the first button
  },
});

// Create the custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#C0C0C0'
    },
  },
});

function Main() {
  const [open, setOpen] = useState(true);
  const [answered, setAnswered] = useState(false);
  const [answer, setAnswer] = useState(null);
  const songsRef = useRef([]);
  const currentSongIndex = useRef(0);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [audioStarted, setAudioStarted] = useState(false);


  const audioPlayer = useRef(null);

  useEffect(() => {
    if (answer === 'yes') {
      const songFiles = [bgSong];
      let loadedSongs = 0;
      const playerList = songFiles.map((songFile, index) => {
        return new Howl({
          src: [songFile],
          loop: true,
          autoplay: false,
          volume: 0.6,
          onend: index === songFiles.length - 1 ? null : handleSongEnded,
          onload: () => {
            loadedSongs++;
            if (loadedSongs === songFiles.length) {
              handleAudioLoad(playerList[index]);
            }
          },
          onplayerror: function() {
            audioPlayer.once('unlock', function() {
              if(!audioPlayer.current.playing()) {
                audioPlayer.play();
              }
            });
          }
        });
      });
      songsRef.current = playerList;
      audioPlayer.current = playerList[0];
      if (audioPlayer.current) {
        audioPlayer.current.once('play', () => setAudioStarted(true));
      }
    }
  }, [answer]);

  const handleClose = (answer) => {
    setOpen(false);
    if (answer === 'yes' || answer === 'no') {
      setAnswered(true);
      setAnswer(answer);
    }
  };

  function handleSongEnded() {
    if (songsRef.current.length > 0) {
      currentSongIndex.current = (currentSongIndex.current + 1) % songsRef.current.length;  
      if (audioPlayer.current) {
        audioPlayer.current.stop();
      }
  
      audioPlayer.current = songsRef.current[currentSongIndex.current];
      if (audioPlayer.current) {
        audioPlayer.current.play();
      }
    }
  }

  function handleAudioLoad(player) {
    if (answer === 'yes') {
      if (audioPlayer.current instanceof Howl && !audioLoaded) {
        audioPlayer.current.play();
      }
    }
    setAudioLoaded(true);
  }

  return (
    <ThemeProvider theme={theme}>
          <Dialog
            open={open}
            onClose={() => handleClose('no')}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
            style: {
              background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.1) 80%)', // linear gradient background
            border: 'none', // remove border
            color: '#fff',
            borderRadius: '10px', // add border radius
            fontFamily: 'Roboto, Arial, sans-serif', // modern font
        },
      }}
    >
      <DialogContent
        sx={{
          height: '100px',
          width: '300px',
          display: 'flex',
          alignItems: 'center',
          padding: '2rem', // add padding
        }}
      >
        <Box display="flex" justifyContent="center">
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              color: '#fff',
              fontSize: '2.5rem',
              textAlign: 'center',
            }}>
            Enable Background Music?
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="center" width="100%">
          <BootstrapButton onClick={() => handleClose('yes')} autoFocus>
            Yes
          </BootstrapButton>
          <BootstrapButton onClick={() => handleClose('no')}>
            No
          </BootstrapButton>
        </Box>
      </DialogActions>
    </Dialog>
      {answered && (answer === 'no' || (answer === 'yes')) && <App musicOption={answer} audioPlayer={audioPlayer} audioStarted={audioStarted} />}
    </ThemeProvider>
  );
}

root.render(
  <React.StrictMode>
    <ControlsProvider>
      <Main />
    </ControlsProvider>
  </React.StrictMode>
);